.services {
  background-color: white;
  /* height: 100vh; */
  padding: 0 9rem;
  margin-bottom: 4rem;
}

.services-header > h1 {
  margin: 4rem 0px;
  padding: 0px 2rem;
  font-size: 2.5rem;
}
.features{
    margin: 5rem 0px;
}
.features>p {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
.features p span { 
     background:#fff; 
     padding:0 10px; 
 }

 .features-content{
    display: flex;
    align-items: center;
    justify-content: space-around;

 }

 .features-content>div>h1{
    margin: 2rem 0;
 }

 .features-image{
    /* border: solid 1px red; */
    height: 350px;
    width: 30vw;
    margin: 2rem 0;
 }

 .features-image>img{
    height: 100%;
    width: 100%;
    object-fit: cover;
 }

@media all and (max-width: 480px) {
    .services {
        padding: 1rem 1rem;
        height: fit-content;
        margin-bottom: 2rem;
      }
    .services-header > h1 {
        margin: 3rem 0px;
        font-size: 1.8rem;
        text-align: center;
      }

      .features-content{
        flex-direction: column;
      }

      .features-image{
        width: 85%;
      }
}
