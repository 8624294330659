.footer {
  background-color: #f4f8fd;
  padding: 0 9rem;
}

@media all and (max-width: 480px) {
    .footer {
      padding: 1rem 1rem;
      height: fit-content;
    }

    .footer>p{
        font-size: 1rem;
    }
}
